import "./style.scss";

import React from "react";

import Logo from "../../components/Logo";

const AuthLayout = ({ children, width, onKeyPressHandler }) => {
  // ...
  return (
    <div
      className="AuthLayout"
      onKeyDown={(e) => {
        if (e.key === "Enter") onKeyPressHandler();
      }}>
      <div className="AuthLayout__header">
        <Logo />
      </div>
      <div className="AuthLayout__content">
        <div className="AuthLayout__content__inner" style={{ width }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
